import './App.css';
import NeedsBar from './NeedsBar';
import HudBar from './HudBar';
import KeyButtons from './KeyButtons';
import { KeyHandler } from './SpeedometrBar';
import SpeedometrBar from './SpeedometrBar';

import React, { useState, useEffect } from 'react';


function App() {

  const interfaces = {
    1: "speedometr"
  };

  const [keys, setKeys] = useState({ Q: false, E: false });

  const [time, setTime] = useState('');
  const [date, setDate] = useState('');

  const [playerMoney, setMoney] = useState(0);
  const [playerWanted, setWanted] = useState(0);

  const [water, setWater] = useState(70);
  const [hunger, setHunger] = useState(40);
  const [health, setHealth] = useState(50);

  const [fuel, setFuel] = useState(100);
  const [speed, setSpeed] = useState(0);
  const [mileage, setMileage] = useState(0);
  const [currentWeapon, setWeapon] = useState(1);

  const [engine, setEngine] = useState(0);
  const [belt, setBelt] = useState(0);
  const [lights, setLights] = useState(0);
  const [accumulator, setAccumulator] = useState(0)

  useEffect(() => {
    const preventSelection = (e) => e.preventDefault();
    const preventCtrlA = (e) => {
        if (e.ctrlKey && e.key === "a") e.preventDefault();
    };

    document.addEventListener("selectstart", preventSelection);
    document.addEventListener("keydown", preventCtrlA);

    return () => {
        document.removeEventListener("selectstart", preventSelection);
        document.removeEventListener("keydown", preventCtrlA);
    };
}, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();

      const hours = currentTime.getHours().toString().padStart(2, '0');
      const minutes = currentTime.getMinutes().toString().padStart(2, '0');
      setTime(`${hours}:${minutes}`);

      const day = currentTime.getDate().toString().padStart(2, '0');
      const month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); 
      const year = currentTime.getFullYear(); 
      setDate(`${day}.${month}.${year}`);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (window.cef) {
      window.cef.emit("game:hud:setComponentVisible", "interface", false);
      window.cef.emit("game:hud:setComponentVisible", "radar", true);
      window.cef.emit("game:data:pollPlayerStats", true, 50);

      window.cef.on("interface:change", (interface_id, status) => {
        let element = interfaces[interface_id];
        if (element) {
          const elementNode = document.getElementById(element); 
      
          if (elementNode) {
            if (status === 1) {
              elementNode.style.display = 'block';
            } else if (status === 0) {
              elementNode.style.display = 'none';
            }
          }
        }
      });
      
      window.cef.on("game:data:playerStats", (hp, max_hp, arm, breath, wanted, weapon, ammo, max_ammo, money, speed) => {
        setMoney(money.toLocaleString('ru-RU'));

        setWanted(wanted);
        setHealth(Math.round(hp));

        setWeapon(weapon);
      });

      window.cef.on("needsbar:satiety", (satiety, water) => {
        setHunger(satiety);
        setWater(water);
      });

      window.cef.on("vehicle:setdata", (speed, fuel, mileage, engine = 0, belt = 0, lights = 0, accumulator = 0) => {
        console.log(speed, fuel, mileage, engine, belt, lights);
        if(speed < 100)
        {
          let obj = document.querySelector(".speed_zero");
          if(obj) obj.style.display = "block";
        }
        else {
          let obj = document.querySelector(".speed_zero");
          if(obj) obj.style.display = "none";
        }

        setMileage(mileage);
        setFuel(fuel);
        setSpeed(speed);
        setEngine(engine);
        setBelt(belt);
        setLights(lights);
        setAccumulator(accumulator);
      });

      window.cef.on("test", () => { 
        for(let i = 1; i !== 6; i++) {
          let name = "star_" + i;
            <img src={`${process.env.PUBLIC_URL}/images/suspect_star.svg`} className={name} alt="logo" />
        }
      });
    }
  }, []);

  return (
    <div className="App">
      <HudBar time={time} date={date} playerMoney={playerMoney} weapon={currentWeapon}/>

      {/* <KeyButtons /> */}
      <KeyHandler>
  {(keys) => <SpeedometrBar fuel={fuel} speed={speed} mileage={mileage} keys={keys} engine={engine} belt={belt} lights={lights} accumulator={accumulator}/>}
</KeyHandler>
      <NeedsBar waterLevel={water} hungerLevel={hunger} healthLevel={health} />
    </div>
  );
}

export default App;
