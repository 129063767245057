import React from "react";
import "./NeedsBar.css";

const Bar = ({ level, color }) => {
  return (
    <div className="bars">
      {[...Array(10)].map((_, index) => (
        <div
          key={index}
          className={`bar ${index < level / 10 ? "active" : "inactive"}`}
          style={{ backgroundColor: color }}
        />
      ))}
    </div>
  );
};

const NeedsBar = ({ waterLevel = 0, hungerLevel = 0, healthLevel = 0 }) => {
  const needs = [
    { name: "water", icon: "water.svg", percent: waterLevel, color: "#00a2ea" },
    { name: "hunger", icon: "hungry.svg", percent: hungerLevel, color: "#EA8C00" },
    { name: "health", icon: "health.svg", percent: healthLevel, color: "#E52D28" }
  ];

  return (
    <div className="needs_bar">
      {needs.map((item) => (
        <div key={item.name} className={`need_item ${item.name}`}>
          <img src={`${process.env.PUBLIC_URL}/images/${item.icon}`} className="need_icon" alt={item.name} />
          <Bar level={item.percent} color={item.color} />
          <span>{item.percent}%</span>
        </div>
      ))}
    </div>
  );
};

export default NeedsBar;
