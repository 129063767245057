import "./HudBar.css";
import fistBackground from './public/images/fist_background.svg';
import money from './public/images/money.svg';
import calendar from './public/images/calendar.svg'
import time_ico from './public/images/time.svg'
import logo from './public/images/logo.png'

const weaponImages = require.context('./public/images', false, /\.png$/);

// Создаем объект с именами файлов и путями
const images = weaponImages.keys().reduce((acc, filePath) => {
  const fileName = filePath.replace('./', '').replace('.png', ''); // получаем имя файла без пути
  acc[fileName] = weaponImages(filePath); // добавляем файл в объект
  return acc;
}, {});

console.log(images);
const HudBar = ({time, date, playerMoney, weapon}) => {

  const weaponImagePath = images[Number(weapon)];
  console.error(weapon, weaponImagePath);

    return (
        <header className="hud-header">
            <img src={logo} className="logo" alt="logo" />
            <span className="server_number">СЕРВЕР 1</span>

            <div className="clocks_data">
              <div>
                <img src={time_ico} alt="time" />
                <span className="time_time">{time}</span>
              </div>
              <div>
                <img src={calendar} className='calendar' alt="calendar" />
                <span className="clock_time">{date}</span>
              </div>
            </div>

            <div className="games_data">
              <div>
                <img src={money} className="money_icon" alt="money" />
                <span className="money_text">{playerMoney} ₽</span>
              </div>
              <div>
                <div className='image_container'>
                  <img src={fistBackground} className="fistbackgroud" alt="fistbackgroud" />
                  <img src={weaponImagePath} className="weapon" alt="weapon" />

                </div>
              </div>
            </div>
        </header>
    );
}


export default HudBar;